



















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { FundDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundProjectDonationRecord",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatStatus(type: string, statusList: any[]) {
      let result = "";
      statusList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundProjectDonationRecord extends Vue {
  @Ref() readonly pagedTableView!: any;
  tableItems: FundDto[] = [];
  statusList: any = [];
  typeList: any = [
    {
      key: "所有",
      value: null,
    },
    {
      key: "月捐",
      value: true,
    },
    {
      key: "单次",
      value: false,
    },
  ];

  queryForm: any = {
    isMonthDonor: null,
  };

  created() {
    this.fetchEnumType();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundProjectStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    params.isMonthDonor = this.queryForm.isMonthDonor;
    return api.fundProjectDonationRecord.getAll(params);
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundProjectDetail",
      params: { id: row.id!.toString() },
    });
  }
}
